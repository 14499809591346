import { findAndReplace } from 'mdast-util-find-and-replace';

const sourceExp = /\[(\d+)\]/g;

/**
 * remarkSource — A remark plugin that finds and replaces source references in Markdown text.
 * It assigns the CSS class 'remark-source-link' and an `index` attribute for tracking.
 *
 */
export function remarkSource() {
  return (tree, _file) => {
    findAndReplace(tree, [[sourceExp, replaceSource as any]]);
  };

  function replaceSource(value, id) {
    return [
      {
        type: 'link',
        url: '',
        data: {
          hProperties: {
            className: 'remark-source-link',
            index: id - 1
          }
        },
        children: [{ type: 'text', value: value.trim() }]
      }
    ];
  }
}
