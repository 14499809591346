import { api } from './api';
import { Session } from './types';

export const sessionList = async (
  params?: Record<any, any>
): Promise<Session[]> => {
  const response = await api.get('/session', { params: params });
  return response.data;
};

export const createSession = async (session: Session): Promise<Session> => {
  const response = await api.post('/session', session);
  return response.data;
};

export const updateSession = async (session: any): Promise<Session> => {
  const response = await api.put('/session', session);
  return response.data;
};

export const deleteSession = async (id: string): Promise<void> => {
  const response = await api.delete('/session', { params: { id } });
  return response.data;
};

export const deleteAllSession = async (): Promise<void> => {
  const response = await api.delete('/session', {
    params: { deleteAll: true }
  });
  return response.data;
};
