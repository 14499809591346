import React, { FC, useRef, useState } from 'react';
import { ReactComponent as LogoIcon } from 'assets/brand/icon.svg';
import { ReactComponent as SessionsIcon } from 'assets/icons/chat-room.svg';
import { ReactComponent as ApiIcon } from 'assets/icons/api.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as CmdIcon } from 'assets/icons/cmd.svg';
import { ReactComponent as TermsIcon } from 'assets/icons/copyleft.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { ReactComponent as MenuCardIcon } from 'assets/icons/menu-card.svg';
import { ReactComponent as CardIcon } from 'assets/icons/card.svg';
import { ReactComponent as ShredderIcon } from 'assets/icons/shredder.svg';
import { ReactComponent as VectorIcon } from 'assets/icons/vector.svg';
// import { ReactComponent as ArchiveIcon } from 'assets/icons/archive.svg';
// import { ReactComponent as ManageArchiveIcon } from 'assets/icons/manage-archive.svg';
// import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { Block } from 'shared/layout/Block';
import {
  Card,
  List,
  ListItem,
  Menu,
  Button as ReaButton,
  Avatar,
  Stack,
  Tooltip,
  Kbd,
  IconButton,
  SmallHeading,
  Text,
  Divider,
  Dialog
} from 'reablocks';
import pkg from '../../../package.json';

import { useAuth } from 'core/Auth';
import { RetentionDialogContainer } from 'shared/layout/RetentionDialog';
import { NavButton } from './NavButton';
import { useMatch, useNavigate } from 'react-router-dom';
import { useHotkeys } from 'reakeys';
import { Button } from 'shared/elements/Button';
import { AdminPortal } from '@frontegg/react';
import { Middleware, offset } from '@floating-ui/react';
import { TrendingPromptsDialogContainer } from 'shared/layout/TrendingPromptsDialog';
import { TrendingPromptsCreateDialogContainer } from '../../shared/layout/TrendingPromptsCreateDialog';
import { isGCAndBWTeam } from '../../core/utils/user';
import { Help } from './Help';
import { Term } from './Term';

// Tooltip position modifiers
const middlewares: Middleware[] = [offset({ mainAxis: 25, alignmentAxis: -5 })];
const BUTTONS_MENU_CLASSNAME =
  'pl-0.5 font-normal leading-[19px] tracking-[1.25px]';

export const Nav: FC = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const [openHelpMenu, setOpenHelpMenu] = useState<boolean>(false);
  const [openPrompt, setOpenPrompt] = useState<boolean>(false);
  const [openPromptCreate, setOpenPromptCreate] = useState<boolean>(false);
  const helpButtonRef = useRef<HTMLButtonElement | null>(null);
  const isOnboarding = useMatch('/onboarding');
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [termOpen, setTermOpen] = useState<boolean>(false);
  const [retentionOpen, setRetentionOpen] = useState<boolean>(false);

  useHotkeys([
    {
      name: 'Sessions',
      keys: 'SHIFT+s',
      category: 'Navigation',
      callback: () => navigate('/sessions')
    },
    {
      name: 'Registries',
      keys: 'SHIFT+r',
      category: 'Navigation',
      callback: () => navigate('/registries')
    }
  ]);

  if (isOnboarding) {
    return null;
  }

  return (
    <>
      <Block
        corners="all"
        cornersColor="white"
        className="h-[calc(100%-60px)] ml-[30px] mt-[30px] mb-[30px] hidden xl:block"
      >
        <Stack
          className="h-full"
          direction="column"
          justifyContent="spaceBetween"
        >
          <Stack direction="column">
            <LogoIcon
              onClick={() => navigate('/')}
              className="w-[50px] h-[50px] mt-2 mb-8 cursor-pointer"
            />
            <Tooltip
              content={
                <>
                  <span className="mr-2">Sessions</span>
                  <Kbd keycode="SHIFT+S" size="small" />
                </>
              }
              placement="right"
              modifiers={middlewares}
            >
              <NavButton icon={<SessionsIcon />} to="/sessions" />
            </Tooltip>
            <Tooltip
              content={
                <>
                  <span className="mr-2">Registry</span>
                  <Kbd keycode="SHIFT+R" size="small" />
                </>
              }
              placement="right-start"
              modifiers={middlewares}
            >
              <NavButton
                icon={<LockIcon className="size-5" />}
                to="/registries"
              />
            </Tooltip>
          </Stack>
          <Stack direction="column" className="pb-4">
            <IconButton
              className="p-0 border-0"
              ref={helpButtonRef}
              variant="outline"
              onClick={() => setOpenHelpMenu(!openHelpMenu)}
            >
              <Avatar
                rounded={true}
                className="border !border-1 !border-gray-100 cursor-pointer relative"
                src={user.profilePictureUrl}
              />
            </IconButton>
            <Menu
              className="relative w-full ml-5 mb-1"
              placement="right-end"
              reference={helpButtonRef}
              open={openHelpMenu}
              onClose={() => setOpenHelpMenu(false)}
            >
              <MenuCardIcon className="absolute left-0 top-0 z-10" />
              <Stack
                direction="column"
                alignItems="start"
                className="relative z-20 p-5 pb-12 min-w-[315px]"
              >
                <Stack
                  direction="row"
                  alignItems="start"
                  className="gap-4 mb-5"
                >
                  <Avatar
                    size={40}
                    rounded={true}
                    className="w-10 mt-0.5"
                    src={user.profilePictureUrl}
                  />

                  <Stack
                    direction="column"
                    alignItems="start"
                    className="gap-0.5"
                  >
                    <SmallHeading className="text-base font-bold text-white custom-l-s custom-l-h">
                      {user?.name}
                    </SmallHeading>
                    <Text className="text-xs font-light small-l-s text-gray-100">
                      {user?.email}
                    </Text>
                  </Stack>
                </Stack>

                <Stack direction="row" className="gap-3">
                  <Button
                    className="pl-0.5 text-white font-normal leading-[19px] tracking-[1.25px]"
                    variant="text"
                    startAdornment={
                      <ProfileIcon className="!w-[22px] !h-[22px] mr-2" />
                    }
                    onClick={() => AdminPortal.show()}
                  >
                    Edit Profile
                  </Button>
                </Stack>

                {isGCAndBWTeam(user) && (
                  <Stack direction="row" className="gap-3">
                    <Button
                      className="pl-0.5 text-white font-normal leading-[19px] tracking-[1.25px]"
                      variant="text"
                      startAdornment={
                        <VectorIcon className="!w-[22px] !h-[22px] mr-2" />
                      }
                      onClick={() => {
                        setOpenHelpMenu(false);
                        setOpenPrompt(true);
                      }}
                    >
                      Manage Trending Prompts
                    </Button>
                  </Stack>
                )}

                <Stack direction="row" className="gap-3">
                  <Button
                    className="pl-0.5 text-white font-normal leading-[19px] tracking-[1.25px]"
                    variant="text"
                    startAdornment={
                      <CardIcon className="!w-[22px] !h-[22px] mr-2" />
                    }
                    onClick={() => {
                      setOpenHelpMenu(false);
                      navigate('/billing');
                    }}
                  >
                    Manage Subscription
                  </Button>
                </Stack>

                <Button
                  className={BUTTONS_MENU_CLASSNAME}
                  variant="text"
                  startAdornment={
                    <ShredderIcon className="!w-[22px] !h-[22px] mr-2" />
                  }
                  onClick={() => {
                    setOpenHelpMenu(false);
                    setRetentionOpen(true);
                  }}
                >
                  Data Retention
                </Button>

                <Divider className="bg-gray-100/[0.24]" />

                <Stack direction="column" alignItems="start" className="mb-5">
                  <Button
                    className={BUTTONS_MENU_CLASSNAME}
                    variant="text"
                    startAdornment={
                      <CmdIcon className="!w-[22px] !h-[22px] mr-2" />
                    }
                    onClick={() => {
                      setOpenHelpMenu(false);
                      window.dispatchEvent(
                        new KeyboardEvent('keypress', {
                          key: '?',
                          shiftKey: true
                        })
                      );
                    }}
                  >
                    Keyboard Shortcuts
                  </Button>
                  <Button
                    className={BUTTONS_MENU_CLASSNAME}
                    variant="text"
                    startAdornment={
                      <ApiIcon className="!w-[22px] !h-[22px] mr-2 fill-gray-100" />
                    }
                    onClick={() => navigate('/swagger')}
                  >
                    Open API
                  </Button>
                  <Button
                    className={BUTTONS_MENU_CLASSNAME}
                    variant="text"
                    startAdornment={
                      <TermsIcon className="!w-[22px] !h-[22px] mr-2" />
                    }
                    onClick={() => {
                      setOpenHelpMenu(false);
                      setTermOpen(true);
                    }}
                  >
                    Terms & Conditions
                  </Button>
                  <Button
                    className={BUTTONS_MENU_CLASSNAME}
                    variant="text"
                    startAdornment={
                      <HelpIcon className="!w-[22px] !h-[22px] mr-2" />
                    }
                    onClick={() => {
                      setOpenHelpMenu(false);
                      setHelpOpen(true);
                    }}
                  >
                    Help & FAQ
                  </Button>
                </Stack>

                <div className="w-full mb-5">
                  <Button
                    className="w-full rounded-none px-7 py-[10px] text-white/[0.7] !text-[15px] font-normal tracking-[1.25px]"
                    variant="outline"
                    onClick={() => logout()}
                    withArrow={true}
                  >
                    Log Out
                    <Stack className="py-0.5 px-[5px] ml-[10px] border border-gray-400">
                      <CmdIcon />L
                    </Stack>
                  </Button>
                </div>

                <Text className="absolute bottom-[18px] text-xs font-light tracking-[1.25px] text-gray-100">
                  Version {pkg.version}
                </Text>
              </Stack>
            </Menu>
          </Stack>
        </Stack>
      </Block>
      <Block
        corners="all"
        cornersColor="white"
        className="mt-6 mr-10 mb-0 ml-6 block xl:hidden"
      >
        <Stack justifyContent="spaceBetween">
          <LogoIcon
            onClick={() => navigate('/')}
            className="w-[50px] h-[50px]"
          />
          <ReaButton
            type="button"
            variant="outline"
            ref={buttonRef}
            onClick={() => setOpen(!open)}
            className="cursor-pointer bg-transparent border border-white"
          >
            Menu
          </ReaButton>
          <Menu
            open={open}
            onClose={() => setOpen(false)}
            reference={buttonRef}
            placement="bottom-end"
          >
            <Card disablePadding className="menu-base !p-0">
              <List className="text-xs p-0">
                <ListItem onClick={() => navigate('/sessions')}>
                  Sessions
                </ListItem>
                <ListItem onClick={() => navigate('/registries')}>
                  Registries
                </ListItem>
              </List>
            </Card>
          </Menu>
        </Stack>
      </Block>
      <Dialog
        header="Help & FAQ"
        open={helpOpen}
        className="backdrop-brightness-[0.15]"
        innerClassName="relative bg-gray-700/[0.8] border border-gray-200 rounded-[10px]"
        onClose={() => setHelpOpen(false)}
      >
        {() => <Help />}
      </Dialog>
      <Dialog
        header="Terms & Conditions"
        open={termOpen}
        className="backdrop-brightness-[0.15]"
        innerClassName="relative bg-gray-700/[0.8] border border-gray-200 rounded-[10px]"
        onClose={() => setTermOpen(false)}
      >
        {() => <Term />}
      </Dialog>
      <TrendingPromptsDialogContainer
        open={openPrompt}
        onAddCLick={() => setOpenPromptCreate(true)}
        onCancel={() => setOpenPrompt(false)}
      />
      <TrendingPromptsCreateDialogContainer
        open={openPromptCreate}
        onCancel={() => setOpenPromptCreate(false)}
      />
      <RetentionDialogContainer
        open={retentionOpen}
        onCancel={() => setRetentionOpen(false)}
      />
    </>
  );
};
