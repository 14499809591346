import { Notifications } from 'reablocks';
import { FC, PropsWithChildren } from 'react';
import { NotificationItem } from './NotificationItem';

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => (
  <Notifications
    components={{
      success: NotificationItem,
      error: NotificationItem,
      default: NotificationItem
    }}
    timeout={3000}
    className="notification"
  >
    {children}
  </Notifications>
);
