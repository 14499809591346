import {
  Button,
  cn,
  Dialog,
  Divider,
  SecondaryHeading,
  Select,
  SelectOption,
  Stack,
  Text
} from 'reablocks';
import React, { FC, useState } from 'react';
import { OnboardButton } from '../../elements/OnboardButton';

export interface TrendingPromptsDialogProps {
  open: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onDeleteAllSessions: () => void;
  sessionRetentionFilter: string;
  onSave: (value: string) => void;
  className?: string;
}

export const RetentionDialog: FC<TrendingPromptsDialogProps> = ({
  open,
  isLoading,
  className,
  onCancel,
  sessionRetentionFilter,
  onSave,
  onDeleteAllSessions
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState(sessionRetentionFilter);

  return (
    <Dialog
      size="500px"
      header={
        <SecondaryHeading className="text-lg font-bold leading-6">
          Retention
        </SecondaryHeading>
      }
      open={open}
      closeOnBackdropClick={false}
      closeOnEscape={false}
      showCloseButton={true}
      onClose={onCancel}
      className="backdrop-brightness-[0.15]"
      innerClassName={cn(
        'relative bg-gray-700/[0.8] border border-gray-200 rounded-[10px]',
        className
      )}
    >
      {() => (
        <Stack direction="column" className="p-4 text-center">
          <Text className="text-gray-100 text-sm">
            Change the session retention policy for all sessions for your user.
          </Text>

          <Select
            disabled={isLoading}
            className="p-4 text-sm text-gray-200 border-gray-500 rounded-[10px] h-14 min-w-80 focus-within:border-white"
            placeholder="Retention Days"
            clearable={false}
            value={selectedPeriod}
            onChange={setSelectedPeriod}
          >
            <SelectOption value="3">3d Retention</SelectOption>
            <SelectOption value="7">7d Retention</SelectOption>
            <SelectOption value="10">10d Retention</SelectOption>
            <SelectOption value="30">30d Retention</SelectOption>
          </Select>

          <Divider />

          <Text className="text-gray-100 text-sm">
            Clear all sessions for your user.
          </Text>

          <Button
            disabled={isLoading}
            variant="outline"
            className="w-1/3 border text-primary-400 !border-primary-400 rounded-[10px] p-4"
            onClick={() => onDeleteAllSessions()}
          >
            Clear All
          </Button>

          <Stack direction="row" justifyContent="end">
            <OnboardButton
              disabled={isLoading}
              className="!border-gray-200 py-4 px-12"
              hideArrow={true}
              onClick={onCancel}
            >
              Cancel
            </OnboardButton>
            <OnboardButton
              disabled={isLoading}
              className="py-4 px-12"
              hideArrow={true}
              onClick={() => onSave(selectedPeriod)}
            >
              {isLoading ? 'Saving...' : 'Save'}
            </OnboardButton>
          </Stack>
        </Stack>
      )}
    </Dialog>
  );
};
