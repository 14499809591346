import { api } from '@frontegg/rest-api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteAllSession } from 'core/api/sessionApi';
import { useAuth } from 'core/Auth';
import { DEFAULT_SESSION_RETENTION } from 'core/constants';
import { useNotification } from 'reablocks';
import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RetentionDialog } from './RetentionDialog';

export interface TrendingPromptsDialogContainerProps {
  open: boolean;
  onCancel: () => void;
  className?: string;
}

export const RetentionDialogContainer: FC<
  TrendingPromptsDialogContainerProps
> = ({ open, className, onCancel }) => {
  const notification = useNotification();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { metadata, setMetadata } = useAuth();
  const { users } = api;
  const [sessionRetention, setSessionRetention] = useState(
    metadata?.sessionRetention || DEFAULT_SESSION_RETENTION
  );
  const [loading, setLoading] = useState(false);
  const { mutate: deleteMutation, isPending } = useMutation({
    mutationFn: deleteAllSession
  });

  const deleteAllSessionsHandler = useCallback(() => {
    deleteMutation(null, {
      onSuccess: async () => {
        onCancel();
        await queryClient.invalidateQueries({
          queryKey: ['sessionList']
        });
        notification.notifySuccess('Sessions deleted successfully');
      }
    });
  }, [deleteMutation, notification, onCancel, queryClient]);

  const saveHandler = useCallback(
    async (value: string) => {
      setLoading(true);
      onCancel();
      setSessionRetention(value);
      const newMeta = {
        ...metadata,
        sessionRetention: value
      };

      // update meta in frontegg
      await users.updateUserProfileV2({
        metadata: JSON.stringify(newMeta)
      });

      // local state update
      setMetadata(newMeta);
      await queryClient.invalidateQueries({
        queryKey: ['sessionList']
      });
      setLoading(false);
    },
    [metadata, onCancel, queryClient, setMetadata, users]
  );

  return (
    <RetentionDialog
      isLoading={isPending || loading}
      className={className}
      open={open}
      onCancel={onCancel}
      onDeleteAllSessions={deleteAllSessionsHandler}
      sessionRetentionFilter={sessionRetention}
      onSave={saveHandler}
    />
  );
};
