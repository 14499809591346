import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import classNames from 'classnames';
import { IconButton, NotificationComponentProps, Stack, Text } from 'reablocks';
import React, { FC } from 'react';

export const NotificationItem: FC<NotificationComponentProps> = ({
  message,
  variant,
  onClose
}) => (
  <div
    className={classNames('border border-solid rounded p-4 min-w-[200px]', {
      'bg-gray-700 border-gray-400 shadow-notification-base':
        variant === 'default',
      'bg-green-700 border-secondary-400 shadow-notification-success':
        variant === 'success',
      'bg-red-400/95 border-primary-50  shadow-notification-error':
        variant === 'error'
    })}
  >
    <Stack direction="row" justifyContent="spaceBetween">
      <Stack direction="row" alignItems="center">
        <Text className="whitespace-pre-wrap text-gray-100 text-xs">
          {message}
        </Text>
      </Stack>
      {onClose && (
        <IconButton
          className="border-0 bg-inherit"
          size="small"
          onClick={onClose}
        >
          <CloseIcon width={16} height={16} />
        </IconButton>
      )}
    </Stack>
  </div>
);
